<template>
  <div class="post">
    <app-content rounded class="post__container">
      <router-link :to="to" class="post__link" />
      <div class="post__inner">
        <blog-post-badges
          v-if="!!post.imageUrl"
          :list="post.groups"
          class="post__badges post__badges--absolute"
        />

        <app-img
          v-if="post.imageUrl"
          class="post__image"
          :src="post.imageUrl"
          :ratio="2"
        />

        <div class="post__content">
          <div class="post__title text-h3">{{ post.title }}</div>

          <blog-post-badges
            v-if="!post.imageUrl"
            :list="post.groups"
            class="post__badges"
          />

          <p class="post__summary">{{ post.summary }}</p>
        </div>

        <div class="post__bottom text-muted text-body2">
          <span class="post__date">{{ post.date }}</span>
        </div>
      </div>
    </app-content>
  </div>
</template>

<script lang="ts" setup>
import BlogPostBadges from 'src/components/blog/BlogPostBadges.vue'
import type { BlogPostPreview } from 'src/models/blog'
import { computed } from 'vue'
import RouterService from 'src/services/router.service'
import { useI18n } from 'src/composables/useI18n'

export interface Props {
  post: BlogPostPreview
}

const props = defineProps<Props>()

const { locale } = useI18n()

const to = computed(() => {
  return RouterService.getRouteLocation('blog.post', { locale: locale.value, slug: props.post.slug })
})
</script>

<style lang="scss" scoped>
.post {
  --title-color: var(--theme-color);
  --pa: var(--md);
  &__container {
    padding: var(--pa);
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;
  }
  &__link {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 2;
  }
  &__inner {
    display: block;
    position: relative;
    text-decoration: none;
    color: var(--theme-color);
    transition: color var(--trs-1);
  }
  &__image {
    --m: calc(var(--pa) / -2);
    flex: 0 0 auto;
    border-radius: var(--global-border-radius-inner);
    margin: var(--m) var(--m) var(--md) var(--m);
  }
  &__title {
    color: var(--title-color);
    transition: color var(--trs-1);
  }
  &__content {
    flex: 1 1 auto;
    margin-bottom: var(--md);
  }
  &__badges {
    margin-bottom: var(--md);
    position: relative;
    z-index: 3;
    pointer-events: none;
    &:deep(> *) {
      pointer-events: all;
    }
    &--absolute {
      margin-bottom: 0;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  body.desktop &:hover {
    --title-color: var(--theme-color-primary);
  }
}
</style>
