<template>
  <div class="blog-list">
    <div class="blog-list__items">
      <div v-for="post in results.items" :key="post.id" class="blog-list__item">
        <blog-post-preview
          class="blog-list__post"
          :post="post"
        />
      </div>
    </div>

    <base-pagination
      v-if="results.pagination"
      class="q-mt-lg"
      :pagination="results.pagination"
      @show-more="emit('showMore')"
    />
  </div>
</template>

<script lang="ts" setup>
import BasePagination from 'src/components/base/BasePagination.vue'
import BlogPostPreview from 'src/components/blog/BlogPostPreview.vue'
import type { BlogResults } from 'src/models/blog'

export interface Emits {
  (e: 'showMore'): void
}

export interface Props {
  results: BlogResults
}

defineProps<Props>()
const emit = defineEmits<Emits>()
</script>

<style lang="scss" scoped>
.blog-list {
  &__items {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: var(--grid-gutter);
  }
  &__post {
    height: 100%;
  }
  @media (max-width: $breakpoint-sm-max) {
    &__items {
      grid-template-columns: 1fr;
    }
  }
}
</style>
